<template>
    <component :is="currentComponent"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "Workflows",
    computed: {
        currentComponent() {
            if (this.$route.query.id)
                return 'ViewWorkflow'

            return 'ViewAllWorkflows'
        },
    },
    components: {
        ViewAllWorkflowsComponent: defineAsyncComponent(() =>
            import("@/views/settings/workflows/ViewAllWorkflows")
        ),
        ViewWorkflowComponent: defineAsyncComponent(() =>
            import("@/views/settings/workflows/ViewWorkflow")
        ),
    }
}
</script>

<style scoped>

</style>